














import { Action } from "vuex-class";
import { Component, Vue, Ref } from "vue-property-decorator";
import { FormBase, FormInput, FormError, FormSubmit } from "@/components/forms";

@Component({
  components: {
    FormBase,
    FormInput,
    FormError,
    FormSubmit,
  },
})
export default class PageLogin extends Vue {
  @Action("auth/login") login!: (payload: LoginPayload) => Promise<void>;

  @Ref() form!: FormClass;

  @Ref() password!: {
    $refs: {
      input: HTMLInputElement;
    };
  };

  errorResponse: ErrorResponse = {
    status: 0,
    errors: [],
  };

  payload: LoginPayload = {
    email: "",
    password: "",
  };

  mounted() {
    if (this.$route.query && this.$route.query.email) {
      this.payload.email = `${this.$route.query.email}`;
      this.password.$refs.input.focus();
    }
  }

  private submit() {
    this.login(this.payload)
      .then(() => this.$router.push({ name: "home" }))
      .catch((errorResponse: ErrorResponse) => (this.errorResponse = errorResponse))
      .finally(() => this.form.reset());
  }
}
